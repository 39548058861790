// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-seiu-uhw-js": () => import("./../../../src/pages/about-seiu-uhw.js" /* webpackChunkName: "component---src-pages-about-seiu-uhw-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-remembrance-js": () => import("./../../../src/pages/remembrance.js" /* webpackChunkName: "component---src-pages-remembrance-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-their-names-js": () => import("./../../../src/pages/their-names.js" /* webpackChunkName: "component---src-pages-their-names-js" */),
  "component---src-pages-their-stories-js": () => import("./../../../src/pages/their-stories.js" /* webpackChunkName: "component---src-pages-their-stories-js" */),
  "component---src-templates-worker-js": () => import("./../../../src/templates/worker.js" /* webpackChunkName: "component---src-templates-worker-js" */)
}

